import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function ScrollToTop() {
    // useLocation hook to grab current pathname.
    const { pathname } = useLocation();

    // On initial load and when the pathname changes, scroll to position (0, 0).
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Return the child routes.
    return <Outlet />;
}
