import React, { lazy, Suspense, useState } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
    RouterProvider,
    useNavigate,
} from "react-router-dom";
import { ContextProvider } from "./components/Context";
import OverlaySpinner from "./components/OverlaySpinner";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import {
    publicResponseInterceptor,
    responseInterceptor,
    responseInterceptorClientAuthenticationToken,
    responseInterceptorPdf,
} from "./services/client";
import "./static/i18n.js";
import lazyRetry from "./static/lazyRetry";
import AuthenticationRoute from "./views/AuthenticationRoute";
import Home from "./views/Home";
import SignIn from "./views/user-management/SignIn";

// https://beta.reactjs.org/reference/react/lazy
// billing-services pages.
const AccountHistory = lazy(() => lazyRetry(() => import("./views/billing-services/AccountHistory")));
const AccountSummary = lazy(() => lazyRetry(() => import("./views/billing-services/AccountSummary")));
const AddCheckingAccount = lazy(() => lazyRetry(() => import("./views/billing-services/AddCheckingAccount")));
const AddInvoiceSubscriber = lazy(() => lazyRetry(() => import("./views/billing-services/AddInvoiceSubscriber")));
const AllEbfSubscribers = lazy(() => lazyRetry(() => import("./views/billing-services/AllEbfSubscribers")));
const AllInvoiceSubscribers = lazy(() => lazyRetry(() => import("./views/billing-services/AllInvoiceSubscribers")));
const CircuitTelNo = lazy(() => lazyRetry(() => import("./views/billing-services/CircuitTelno")));
const EbfSubscribers = lazy(() => lazyRetry(() => import("./views/billing-services/EbfSubscribers")));
const EditCheckingAccount = lazy(() => lazyRetry(() => import("./views/billing-services/EditCheckingAccount")));
const EditInvoiceSubscriber = lazy(() => lazyRetry(() => import("./views/billing-services/EditInvoiceSubscriber.jsx")));
const Invoices = lazy(() => lazyRetry(() => import("./views/billing-services/Invoices")));
const InvoiceSubscribers = lazy(() => lazyRetry(() => import("./views/billing-services/InvoiceSubscribers")));
const ManageOnlinePayments = lazy(() => lazyRetry(() => import("./views/billing-services/ManageOnlinePayments")));
const ManagePaymentMethods = lazy(() => lazyRetry(() => import("./views/billing-services/ManagePaymentMethods")));
const MonthlyRecurringCharges = lazy(() => lazyRetry(() => import("./views/billing-services/MonthlyRecurringCharges")));
const OneTimePaymentPage = lazy(() => lazyRetry(() => import("./views/billing-services/OneTimePaymentPage")));
const PaperlessBilling = lazy(() => lazyRetry(() => import("./views/billing-services/PaperlessBilling")));
const PaymentHistory = lazy(() => lazyRetry(() => import("./views/billing-services/PaymentHistory")));
const ReportPreferences = lazy(() => lazyRetry(() => import("./views/billing-services/ReportPreferences")));
const SearchInvoices = lazy(() => lazyRetry(() => import("./views/billing-services/SearchInvoices")));
// contacts pages.
const Contacts = lazy(() => lazyRetry(() => import("./views/contacts/Contacts")));
// e911-provisioning pages.
const E911Provisioning = lazy(() => lazyRetry(() => import("./views/e911-provisioning/E911Provisioning")));
// error-pages pages.
const AccountProfileProblem = lazy(() => lazyRetry(() => import("./views/error-pages/AccountProfileProblem")));
const PageNotFound = lazy(() => lazyRetry(() => import("./views/error-pages/PageNotFound")));
const PermissionIssue = lazy(() => lazyRetry(() => import("./views/error-pages/PermissionIssue")));
const SiteWork = lazy(() => lazyRetry(() => import("./views/error-pages/SiteWork")));
const TechnicalIssue = lazy(() => lazyRetry(() => import("./views/error-pages/TechnicalIssue")));
const TemporaryIssue = lazy(() => lazyRetry(() => import("./views/error-pages/TemporaryIssue")));
const TemporaryIssuePublic = lazy(() => lazyRetry(() => import("./views/error-pages/TemporaryIssuePublic")));
const TwoStepVerification = lazy(() => lazyRetry(() => import("./views/error-pages/TwoStepVerification")));
const UserIssue = lazy(() => lazyRetry(() => import("./views/error-pages/UserIssue")));
// product-data-services pages.
const MetricsReporting = lazy(() => lazyRetry(() => import("./views/product-data-services/MetricsReporting")));
const OrderDetails = lazy(() => lazyRetry(() => import("./views/product-data-services/OrderDetails")));
const Orders = lazy(() => lazyRetry(() => import("./views/product-data-services/Orders")));
const ProductServices = lazy(() => lazyRetry(() => import("./views/product-data-services/ProductServices")));
const ViewNetworkStatus = lazy(() => lazyRetry(() => import("./views/product-data-services/ViewNetworkStatus")));
// reports-management pages.
const UploadReports = lazy(() => lazyRetry(() => import("./views/reports-management/UploadReports")));
const Reports = lazy(() => lazyRetry(() => import("./views/reports-management/Reports.jsx")));
// support-services pages.
const CreateTicketHome = lazy(() =>
    lazyRetry(() => import("./views/support-services/CreateTicketComponents/CreateTicketHome"))
);
const ViewTicketDetails = lazy(() =>
    lazyRetry(() => import("./views/support-services/ViewTicketComponents/ViewTicketDetails"))
);
const ViewTicketList = lazy(() =>
    lazyRetry(() => import("./views/support-services/ViewTicketComponents/ViewTicketList"))
);
const AccountSupportTeam = lazy(() => lazyRetry(() => import("./views/support-services/AccountSupportTeam")));
const AddNotification = lazy(() => lazyRetry(() => import("./views/support-services/AddNotification")));
const EditNotification = lazy(() => lazyRetry(() => import("./views/support-services/EditNotification")));
const Notifications = lazy(() => lazyRetry(() => import("./views/support-services/Notifications")));
const ProductInformation = lazy(() => lazyRetry(() => import("./views/support-services/ProductInformation")));
const ProductPortals = lazy(() => lazyRetry(() => import("./views/support-services/ProductPortals")));
const ScheduledMaintenance = lazy(() => lazyRetry(() => import("./views/support-services/ScheduledMaintenance")));
const ScheduledMaintenanceCalendar = lazy(() =>
    lazyRetry(() => import("./views/support-services/ScheduledMaintenanceCalendar"))
);
const ScheduledMaintenanceDetails = lazy(() =>
    lazyRetry(() => import("./views/support-services/ScheduledMaintenanceDetails"))
);
const SupportDocuments = lazy(() => lazyRetry(() => import("./views/support-services/SupportDocuments")));
// user-management pages.
const AddUser = lazy(() => lazyRetry(() => import("./views/user-management/AddUser")));
const ChangePassword = lazy(() => lazyRetry(() => import("./views/user-management/ChangePassword")));
const EditUser = lazy(() => lazyRetry(() => import("./views/user-management/EditUser")));
const ManageAllUsers = lazy(() => lazyRetry(() => import("./views/user-management/ManageAllUsers")));
const ManageUsers = lazy(() => lazyRetry(() => import("./views/user-management/ManageUsers")));
const MyProfile = lazy(() => lazyRetry(() => import("./views/user-management/MyProfile")));
const RequestAccess = lazy(() => lazyRetry(() => import("./views/user-management/RequestAccess")));
const RequestResetPassword = lazy(() => lazyRetry(() => import("./views/user-management/RequestResetPassword")));
const ResetPassword = lazy(() => lazyRetry(() => import("./views/user-management/ResetPassword")));
const ViewUser = lazy(() => lazyRetry(() => import("./views/user-management/ViewUser")));

// React Query client.
// https://tanstack.com/query/v4/docs/react/reference/QueryClient
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
            retry: 0,
        },
    },
});

// Component to assign axios interceptors.
function HttpResponseInterceptor() {
    const navigate = useNavigate();

    const [interceptAssigned, setInterceptAssigned] = useState(false);

    if (!interceptAssigned) {
        publicResponseInterceptor(navigate);
        responseInterceptor(navigate);
        responseInterceptorPdf(navigate);
        responseInterceptorClientAuthenticationToken(navigate);
        setInterceptAssigned(true);
    }

    return <Outlet />;
}

// https://reactrouter.com/en/main/routers/create-browser-router
const browserRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<HttpResponseInterceptor />}>
            <Route element={<ScrollToTop />}>
                <Route element={<PrivateRoute />}>
                    <Route element={<AuthenticationRoute />}>
                        {/* Home */}
                        <Route path="/" element={<Home />} />
                        {/* Billing */}
                        <Route path="/account-history" element={<AccountHistory />} />
                        <Route path="/account-summary" element={<AccountSummary />} />
                        <Route path="/add-checking-account" element={<AddCheckingAccount />} />
                        <Route path="/circuit-telno" element={<CircuitTelNo />} />
                        <Route path="/edit-checking-account" element={<EditCheckingAccount />} />
                        <Route path="/invoices" element={<Invoices />} />
                        <Route path="/manage-online-payments" element={<ManageOnlinePayments />} />
                        <Route path="/monthly-recurring-charges" element={<MonthlyRecurringCharges />} />
                        <Route path="/manage-payment-methods" element={<ManagePaymentMethods />} />
                        <Route path="/one-time-payment" element={<OneTimePaymentPage />} />
                        <Route path="/paperless-billing" element={<PaperlessBilling />} />
                        <Route path="/payment-history" element={<PaymentHistory />} />

                        {/* Account */}
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/metrics-reporting" element={<MetricsReporting />} />
                        <Route path="/product-services" element={<ProductServices />} />
                        <Route path="/reports" element={<Reports />} />

                        {/* Support */}
                        <Route path="/create-ticket" element={<CreateTicketHome />} />
                        <Route path="/account-support-team" element={<AccountSupportTeam />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/order-details" element={<OrderDetails />} />
                        <Route path="/support-documents" element={<SupportDocuments />} />
                        <Route path="/view-network-status" element={<ViewNetworkStatus />} />
                        <Route path="/view-tickets" element={<ViewTicketList />} />
                        <Route path="/scheduled-maintenance" element={<ScheduledMaintenance />} />
                        <Route path="/view-ticket-details" element={<ViewTicketDetails />} />
                        <Route path="/scheduled-maintenance-details" element={<ScheduledMaintenanceDetails />} />
                        <Route path="/scheduled-maintenance-calendar" element={<ScheduledMaintenanceCalendar />} />

                        {/* Product Resources */}
                        <Route path="/product-information" element={<ProductInformation />} />
                        <Route path="/product-portals" element={<ProductPortals />} />

                        {/* Admin */}
                        <Route path="/ebf-subscribers" element={<EbfSubscribers />} />
                        <Route path="/invoice-subscribers" element={<InvoiceSubscribers />} />
                        <Route path="/add-invoice-subscriber" element={<AddInvoiceSubscriber />} />
                        <Route path="/edit-invoice-subscriber" element={<EditInvoiceSubscriber />} />
                        <Route path="/report-preferences" element={<ReportPreferences />} />
                        <Route path="/manage-users" element={<ManageUsers />} />

                        {/* Internal Admin */}
                        <Route path="/all-ebf-subscribers" element={<AllEbfSubscribers />} />
                        <Route path="/all-invoice-subscribers" element={<AllInvoiceSubscribers />} />
                        <Route path="/manage-all-users" element={<ManageAllUsers />} />
                        <Route path="/search-invoices" element={<SearchInvoices />} />
                        <Route path="/upload-reports" element={<UploadReports />} />
                        <Route path="/notifications" element={<Notifications />} />
                        <Route path="/add-notification" element={<AddNotification />} />
                        <Route path="/edit-notification" element={<EditNotification />} />

                        {/* User Managment Pages shared with Admin and Internal Admin */}
                        <Route path="/add-user" element={<AddUser />} />
                        <Route path="/edit-user" element={<EditUser />} />
                        <Route path="/view-user" element={<ViewUser />} />

                        {/* My Preferences */}
                        <Route path="/change-password" element={<ChangePassword />} />
                        <Route path="/my-profile" element={<MyProfile />} />

                        {/* Private Error Pages */}
                        <Route path="/account-profile-problem" element={<AccountProfileProblem />} />
                        <Route path="/temporary-issue" element={<TemporaryIssue />} />
                        <Route path="/permission-issue" element={<PermissionIssue />} />

                        {/* Site Work Page */}
                        <Route path="/site-work" element={<SiteWork />} />
                    </Route>
                </Route>

                {/* Public Routes. */}
                <Route path="/e911-provisioning" element={<E911Provisioning />} />
                <Route path="/request-access" element={<RequestAccess />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/request-reset-password" element={<RequestResetPassword />} />
                <Route path="/signin" element={<SignIn />} />

                {/* Public Error Routes. */}
                <Route path="/technical-issue" element={<TechnicalIssue />} />
                <Route path="/ext-temporary-issue" element={<TemporaryIssuePublic />} />
                <Route path="/user-issue" element={<UserIssue />} />
                <Route path="/two-step-verification" element={<TwoStepVerification />} />

                {/* Page Not Found Route. */}
                <Route path="/page-not-found" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Route>
    )
);

const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Suspense fallback={<OverlaySpinner />}>
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    <ContextProvider>
                        <RouterProvider router={browserRouter} />
                    </ContextProvider>
                </QueryClientProvider>
            </HelmetProvider>
        </Suspense>
    </React.StrictMode>
);
reportWebVitals();
