import { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { GlobalContext } from "../components/Context";
import Header from "../components/Header";
import Indent from "../components/Indent";
import { client } from "../services/client";
import { routeDictionary, routeDictionaryReverse, routePostFix } from "../static/routeDictionary";

export default function Home() {
    // Hook for the current location.
    const location = useLocation();

    // Function from i18next for translation.
    const { t: translate } = useTranslation(["home", "routes", "common"]);

    // Outlet Context.
    const outletContext = useOutletContext();

    // useQuery for getAccountSupportTeamInfoDto.
    const getAccountSupportTeamInfoDto = outletContext?.getAccountSupportTeamInfoDto;

    // Convenience constant.
    const powerFiberFlag = getAccountSupportTeamInfoDto?.data?.data?.accountSupportTeamInfoDto?.powerFiberFlag;

    const globalState = useContext(GlobalContext);

    // Constant to store the logged in users first name.
    const firstName = globalState?.getFirstName();

    // mediaMatch constant to set max width to 767 pixels.
    const mediaMatch = window.matchMedia("(max-width: 767px)");

    // Boolean state for that checkes if the screen width matches the mad-width pixels.
    const [matches, setMatches] = useState(mediaMatch.matches);

    // Styles for the background image.
    const styles = {
        backgroundImage: matches
            ? `url(${process.env.REACT_APP_STATIC_IMAGES + "/cly-bkgrnd-img.jpg"})`
            : `url(${process.env.REACT_APP_STATIC_IMAGES + "/customers-like-you.jpg"})`,
    };

    // useQuery method to fet the home page info.
    // https://react-query.tanstack.com/guides/queries
    const getHomeInfo = useQuery(
        ["/lcp-user-management-service/getHomeInfo", outletContext?.companyNumber],
        () => {
            return client.post("/lcp-user-management-service/getHomeInfo");
        },
        {
            enabled: outletContext?.getBasicAccountInfoDtoList?.isSuccess,
        }
    );

    // Method to map the quick links.
    const mapLinks = () => {
        return getHomeInfo?.data?.data?.quickLinks.map((link) => {
            // Always skip for power fiber.
            if (powerFiberFlag && link === "product_portals") {
                return;
            }
            return (
                <Nav.Item key={link}>
                    <Nav.Link className="lcp-nav-quick-link" as={Link} to={routeDictionary[link] + "?ql"}>
                        <h6 className="lcp-quick-link">{translate(link)}</h6>
                    </Nav.Link>
                </Nav.Item>
            );
        });
    };

    // useEffect method for mediaMatch onChange.
    useEffect(() => {
        // Media match on change used to check when pixel screen size changes and set the matches state.
        mediaMatch.onchange = (event) => {
            if (event.matches) {
                setMatches(true);
            } else {
                setMatches(false);
            }
        };
    });

    // Render the Home page.
    return (
        <>
            <Helmet>
                <title>{translate(routeDictionaryReverse[location?.pathname], { ns: "routes" }) + routePostFix}</title>
            </Helmet>
            {getAccountSupportTeamInfoDto?.isSuccess ? (
                <>
                    <Header />
                    <Container
                        className={powerFiberFlag ? "lcp lcp-container lcp-container-power-fiber" : "lcp lcp-container"}
                        fluid
                        style={powerFiberFlag ? { backgroundColor: "#ececec" } : { backgroundColor: "black" }}
                    >
                        <Row
                            style={
                                powerFiberFlag
                                    ? {
                                          backgroundImage: `url(${
                                              process.env.REACT_APP_STATIC_IMAGES + "/Portal-Home-3-2.png"
                                          }`,
                                          backgroundPositionX: "center",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundColor: "#ececec",
                                      }
                                    : {
                                          backgroundImage: `url(${
                                              process.env.REACT_APP_STATIC_IMAGES + "/hero-desktop-bkrnd-lp.jpg"
                                          }`,
                                          backgroundPositionX: "center",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundColor: "black",
                                      }
                            }
                        >
                            <Row className={powerFiberFlag ? "lcp-home-section-power-fiber" : "lcp-home-section"}>
                                <Col>
                                    <h1>{translate("home.title.hello", { firstName: firstName })}</h1>
                                    <h4>{translate("home.subtitle")}</h4>
                                </Col>
                            </Row>
                            <Row className="lcp-home-sub-section">
                                <Col>
                                    <Nav>
                                        <Nav.Item>
                                            <Nav.Link disabled>
                                                <h6>{translate("quickLinks.title")}</h6>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {getHomeInfo?.isSuccess && mapLinks()}
                                    </Nav>
                                </Col>
                            </Row>
                        </Row>
                        {/*zoomhere*/}
                        <Container className={powerFiberFlag ? "lcp lcp-container-power-fiber" : "lcp"}>
                            <Row
                                xs={1}
                                sm={1}
                                md={2}
                                lg={3}
                                xl={3}
                                className="justify-content-center lcp-card-row gx-5"
                                style={powerFiberFlag ? { backgroundColor: "#ececec" } : { backgroundColor: "black" }}
                            >
                                <Col className="lcp-card-override">
                                    <Card className="lcp-card-small-yellow">
                                        <Card.Body>
                                            <Row>
                                                <p>
                                                    {powerFiberFlag ? (
                                                        <div
                                                            style={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "white",
                                                                width: "70px",
                                                                height: "70px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_STATIC_IMAGES +
                                                                    "/Alert-Bell.svg"
                                                                }
                                                                style={{ marginTop: "8px", display: "inline-block" }}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES + "/alert-icon.png"
                                                            }
                                                            width={40}
                                                            height={47.26}
                                                            alt=""
                                                        />
                                                    )}
                                                </p>
                                                <h5>LATEST ALERT</h5>
                                            </Row>
                                            <br />
                                            <Row>
                                                <h4>
                                                    {powerFiberFlag
                                                        ? "The Power to Operate at Your Best"
                                                        : "New LP DDoS Shield is now available!"}
                                                </h4>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col md={{ offset: 1, span: 10 }}>
                                                    <a
                                                        className="lcp-button-link-black"
                                                        href="https://lightpathfiber.com/services/managed-security/ddos"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Learn More{" "}
                                                        <img
                                                            className="lcp-button-link-icon"
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES +
                                                                "/yellow-btn-arrow.png"
                                                            }
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="lcp-card-override">
                                    <Card className="lcp-card-small">
                                        <Card.Body>
                                            <Card.Title>
                                                {powerFiberFlag ? (
                                                    <div
                                                        style={{
                                                            borderRadius: "50%",
                                                            backgroundColor: "white",
                                                            width: "70px",
                                                            height: "70px",
                                                            textAlign: "center",
                                                            border: "4px #e0ff00 solid",
                                                        }}
                                                    >
                                                        <img
                                                            width={40}
                                                            height={40}
                                                            style={{ marginTop: "11px", display: "inline-block" }}
                                                            alt="logo"
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES +
                                                                "/doc-download.svg"
                                                            }
                                                        ></img>
                                                    </div>
                                                ) : (
                                                    <img
                                                        width={70}
                                                        height={70}
                                                        alt="logo"
                                                        src={process.env.REACT_APP_STATIC_IMAGES + "/feature-icon.png"}
                                                    ></img>
                                                )}
                                            </Card.Title>
                                            <p className="lcp-card-info">
                                                <span className="lcp-bold">Featured Resources</span>
                                            </p>
                                            <p className="lcp-card-info">
                                                <a
                                                    className="lcp-link"
                                                    href="https://portal.lightpathfiber.com/portal-tour"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Portal Tour &gt;
                                                </a>
                                            </p>
                                            <p className="lcp-card-info">
                                                <a
                                                    className="lcp-link"
                                                    href="https://lightpathfiber.com/customer-referral"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Referral Program &gt;
                                                </a>
                                            </p>
                                            <p className="lcp-card-info">
                                                <a
                                                    className="lcp-card-info lcp-link"
                                                    href="https://lightpathfiber.com"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    New Lightpath Site &gt;
                                                </a>
                                            </p>
                                            <p className="lcp-card-info">
                                                <a
                                                    className="lcp-card-info lcp-link"
                                                    href="/support-documents"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Support Documents &gt;
                                                </a>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="lcp-card-override">
                                    <Card className="lcp-card-small">
                                        {outletContext?.accountSupportTeamDto && (
                                            <Card.Body>
                                                <Card.Title>
                                                    {powerFiberFlag ? (
                                                        <div
                                                            style={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "white",
                                                                width: "70px",
                                                                height: "70px",
                                                                textAlign: "center",
                                                                border: "4px #e0ff00 solid",
                                                            }}
                                                        >
                                                            <img
                                                                width={40}
                                                                height={40}
                                                                style={{ marginTop: "13px", display: "inline-block" }}
                                                                alt="logo"
                                                                src={
                                                                    process.env.REACT_APP_STATIC_IMAGES + "/Contact.svg"
                                                                }
                                                            ></img>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            width={70}
                                                            height={70}
                                                            alt="logo"
                                                            src={process.env.REACT_APP_STATIC_IMAGES + "/user-icon.png"}
                                                        ></img>
                                                    )}
                                                </Card.Title>
                                                <p className="lcp-card-info">
                                                    <span className="lcp-bold">Account Support</span>
                                                </p>
                                                <p className="lcp-card-info">
                                                    {translate("label.callCustomerCare", { ns: "common" })}
                                                    <br />
                                                    <Indent />
                                                    <img
                                                        alt="logo"
                                                        src={
                                                            process.env.REACT_APP_STATIC_IMAGES + "/telephone-fill.svg"
                                                        }
                                                        width={16}
                                                        height={16}
                                                    ></img>
                                                    <Indent length={5} />
                                                    <Card.Link
                                                        className="lcp-card-info lcp-link"
                                                        href={
                                                            "tel:" + outletContext?.accountSupportTeamDto?.phoneNumber
                                                        }
                                                        target="_blank"
                                                    >
                                                        {outletContext?.accountSupportTeamDto?.phoneNumber}
                                                    </Card.Link>
                                                    <br />
                                                    <Indent />
                                                    <img
                                                        alt="logo"
                                                        src={
                                                            process.env.REACT_APP_STATIC_IMAGES +
                                                            "/envelope-at-fill.svg"
                                                        }
                                                        width={16}
                                                        height={16}
                                                    ></img>
                                                    <Indent length={5} />
                                                    <Card.Link
                                                        className="lcp-card-info lcp-link"
                                                        href={
                                                            "mailto: " +
                                                            outletContext?.accountSupportTeamDto?.emailAddress
                                                        }
                                                        target="_blank"
                                                    >
                                                        {outletContext?.accountSupportTeamDto?.emailAddress}
                                                    </Card.Link>
                                                    <br />
                                                    <Indent />
                                                    <img
                                                        alt="logo"
                                                        src={
                                                            process.env.REACT_APP_STATIC_IMAGES +
                                                            "/exclamation-triangle-fill.svg"
                                                        }
                                                        width={16}
                                                        height={16}
                                                    ></img>
                                                    <Indent length={5} />
                                                    <Card.Link
                                                        className="lcp-card-info lcp-link"
                                                        href="/docs/Lightpath_NMC_Escalation_Contacts.pdf"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {translate("label.lightpath.nmc.escalation", { ns: "common" })}
                                                    </Card.Link>
                                                </p>
                                                <Card.Link
                                                    className="lcp-card-info lcp-link"
                                                    as={Link}
                                                    to="/account-support-team"
                                                    target="_blank"
                                                >
                                                    {translate("label.accountSupportTeamPage", { ns: "common" })}
                                                </Card.Link>
                                            </Card.Body>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container fluid className="lcp lcp-container">
                        {powerFiberFlag ? (
                            <Row
                                className="lcp-hero-image-left-content"
                                style={{
                                    backgroundImage: `url(${
                                        process.env.REACT_APP_STATIC_IMAGES + "/Portal-Home-3-1.png"
                                    }`,
                                    backgroundPositionX: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundColor: "#ececec",
                                }}
                            >
                                <Row>
                                    <Col xs={9} sm={{ span: 4, offset: 1 }}>
                                        <h2>More Power for Small Business.</h2>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://lightpathfiber.com/about-us"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Learn More{" "}
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Row>
                        ) : (
                            <Row className="lcp-hero-image-left-content" style={styles}>
                                <Row>
                                    <Col xs={9} sm={{ span: 4, offset: 1 }}>
                                        <h2>
                                            Next Generation Network <br /> Next Generation Customer Service
                                        </h2>
                                        <h5>
                                            Lightpath is revolutionizing how organizations connect to their digital
                                            destinations.
                                        </h5>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://lightpathfiber.com/about-us"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Learn More{" "}
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={9} sm={{ span: 4, offset: 1 }}>
                                        <br />
                                        <h2>The Lightpath Difference Video</h2>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://vimeo.com/677909097"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View Here{" "}
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Row>
                        )}
                    </Container>
                    {/*zoomhere*/}
                    <Container
                        fluid
                        style={powerFiberFlag ? { backgroundColor: "#ececec" } : { backgroundColor: "white" }}
                    >
                        <Container className={powerFiberFlag ? "lcp lcp-container-power-fiber" : "lcp "}>
                            <Row
                                xs={1}
                                sm={1}
                                md={2}
                                lg={3}
                                xl={3}
                                className="justify-content-center lcp-card-row gx-5"
                                style={powerFiberFlag ? { backgroundColor: "#ececec" } : { backgroundColor: "white" }}
                            >
                                <Col className="lcp-card-override-large">
                                    <Container className="lcp lcp-container" fluid>
                                        {powerFiberFlag ? (
                                            <Row
                                                style={{
                                                    height: "275px",
                                                    backgroundColor: "black",
                                                }}
                                            >
                                                <Col>
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_STATIC_IMAGES + "/PF_UC.svg"}
                                                            style={{ marginTop: "100px", display: "inline-block" }}
                                                            alt=""
                                                            width={90}
                                                            height={90}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row
                                                style={{
                                                    backgroundImage: `url(${
                                                        process.env.REACT_APP_STATIC_IMAGES + "/three-col-img01.jpg"
                                                    }`,
                                                    height: "275px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover",
                                                }}
                                            />
                                        )}
                                    </Container>
                                    <Card className="lcp-card-large">
                                        <Card.Body>
                                            <Row>
                                                <Col md={{ span: 11, offset: 1 }}>
                                                    <h4>
                                                        {powerFiberFlag
                                                            ? "Power Business Voice/UC"
                                                            : "Private Networks"}
                                                    </h4>
                                                    <p className="lcp-card-large-sub-heading">
                                                        {powerFiberFlag
                                                            ? "Stay connected with Voice Anywhere"
                                                            : "Private, dedicated fiber solutions designed for your business"}
                                                    </p>
                                                    <a
                                                        className="lcp-button-link-yellow"
                                                        href="https://lightpathfiber.com/services/private-networks"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Read More{" "}
                                                        <img
                                                            className="lcp-button-link-icon"
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES +
                                                                "/black-btn-arrow.png"
                                                            }
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="lcp-card-override-large">
                                    <Container className="lcp lcp-container" fluid>
                                        {powerFiberFlag ? (
                                            <Row
                                                style={{
                                                    backgroundColor: "black",
                                                    height: "275px",
                                                }}
                                            >
                                                <Col>
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_STATIC_IMAGES + "/PF_Shield.svg"}
                                                            style={{ marginTop: "100px", display: "inline-block" }}
                                                            alt=""
                                                            width={90}
                                                            height={90}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row
                                                style={{
                                                    backgroundImage: `url(${
                                                        process.env.REACT_APP_STATIC_IMAGES + "/three-col-img02.jpg"
                                                    }`,
                                                    height: "275px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover",
                                                }}
                                            />
                                        )}
                                    </Container>
                                    <Card className="lcp-card-large">
                                        <Card.Body>
                                            <Row>
                                                <Col md={{ span: 11, offset: 1 }}>
                                                    <h4>{powerFiberFlag ? "DDoS Power Shield" : "Cloud Connect"}</h4>
                                                    <p className="lcp-card-large-sub-heading">
                                                        {powerFiberFlag
                                                            ? "Protection that keeps your Internet up and running"
                                                            : "Dedicated, secure connectivity to a large ecosystem of Cloud providers"}
                                                    </p>
                                                    <a
                                                        className="lcp-button-link-yellow"
                                                        href="https://lightpathfiber.com/applications/cloud-connect"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Read More{" "}
                                                        <img
                                                            className="lcp-button-link-icon"
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES +
                                                                "/black-btn-arrow.png"
                                                            }
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="lcp-card-override-large">
                                    <Container className="lcp lcp-container" fluid>
                                        {powerFiberFlag ? (
                                            <Row
                                                style={{
                                                    backgroundColor: "black",
                                                    height: "275px",
                                                }}
                                            >
                                                <Col>
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_STATIC_IMAGES + "/PF_WiFi.svg"}
                                                            style={{ marginTop: "100px", display: "inline-block" }}
                                                            alt=""
                                                            width={90}
                                                            height={90}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row
                                                style={{
                                                    backgroundImage: `url(${
                                                        process.env.REACT_APP_STATIC_IMAGES + "/three-col-img03.jpg"
                                                    }`,
                                                    height: "275px",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover",
                                                }}
                                            />
                                        )}
                                    </Container>
                                    <Card className="lcp-card-large">
                                        <Card.Body>
                                            <Row>
                                                <Col md={{ span: 11, offset: 1 }}>
                                                    <h4>{powerFiberFlag ? "Power WiFi" : "Burstable Bandwidth"}</h4>
                                                    <p className="lcp-card-large-sub-heading">
                                                        {powerFiberFlag
                                                            ? "More powerful WiFi for employees and guests"
                                                            : "Affordable flexibility for today and the future"}
                                                    </p>
                                                    <a
                                                        className="lcp-button-link-yellow"
                                                        href="https://lightpathfiber.com/articles/burstable-bandwidth"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Read More{" "}
                                                        <img
                                                            className="lcp-button-link-icon"
                                                            src={
                                                                process.env.REACT_APP_STATIC_IMAGES +
                                                                "/black-btn-arrow.png"
                                                            }
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container
                        className={
                            powerFiberFlag
                                ? "lcp lcp-container lcp-banner-container-power-fiber"
                                : "lcp lcp-container lcp-banner-container"
                        }
                        fluid
                    >
                        <Row
                            className="lcp-banner-image"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_STATIC_IMAGES + "/haq-line.png"}`,
                            }}
                        ></Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <p>
                                    {powerFiberFlag ? (
                                        <img
                                            width={92}
                                            height={82}
                                            alt="question"
                                            style={{ marginTop: "-40px" }}
                                            src={process.env.REACT_APP_STATIC_IMAGES + "/Question.svg"}
                                        ></img>
                                    ) : (
                                        <img
                                            width={77}
                                            height={92.08}
                                            alt="question"
                                            src={process.env.REACT_APP_STATIC_IMAGES + "/question-icon.png"}
                                        ></img>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row className="lcp-banner-container-info">
                            <Col className="d-flex justify-content-center">
                                <h1>Have a question?</h1>
                            </Col>
                        </Row>
                        <br />
                        <Row className="lcp-banner-container-info">
                            <Col className="d-flex justify-content-center text-center">
                                {powerFiberFlag ? (
                                    <p>Our specialists are here to help</p>
                                ) : (
                                    <p>
                                        Our specialists are here to help you with any business solution assistance you
                                        may need.
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <a className="lcp-button-link-yellow" href={"mailto: care@lightpathfiber.com"}>
                                    Contact Us
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <Container className="lcp-container-fallback" fluid></Container>
            )}
        </>
    );
}
