import { Fragment } from "react";

export default function Indent({ length = 6 }) {
    return (
        <>
            {Array.from({ length: length }, (_element, index) => (
                <Fragment key={"indent" + index}>&nbsp;</Fragment>
            ))}
        </>
    );
}
