/**
 * The default value for minimum password length fields.
 */
export const DEFAULT_MIN_PASSWORD_LENGTH = 8;

/**
 * The default value for maximum password length.
 */
export const DEFAULT_MAX_PASSWORD_LENGTH = 50;

/**
 * The color code for toggle on switch.
 */
export const TOGGLE_SWITCH_ON_COLOR = "#E0FF00";

/**
 * The color code for toggle off switch.
 */
export const TOGGLE_SWITCH_OFF_COLOR = "#E6E6E6";

/**
 * Name of the common.json file used for translation.
 */
export const COMMON_FILE_NAME = "common";

/**
 * Name of the user-maintenance.json file used for translation.
 */
export const USER_MAINTENANCE_FILE_NAME = "user-maintenance";

/**
 * Name of the routes.json file used for translation.
 */
export const ROUTES_FILE_NAME = "routes";
