import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

export default function LogoBar(props) {
    return (
        <Container className="lcp-container" fluid>
            <Row id="divLogoRow">
                <Col className="divCenterColumn" md={{ span: 6, offset: 3 }}>
                    <Image id="imgLogo" src={props.src} alt={props.alt} height={45}></Image>
                </Col>
            </Row>
        </Container>
    );
}
