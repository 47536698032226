import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router";
import Footer from "../components/Footer";
import OverlaySpinner from "../components/OverlaySpinner";
import useGetBasicAccountInfoDtoList from "../hooks/useGetBasicAccountInfoDtoList";
import useGetDisplayableUiNotificationDtoList from "../hooks/useGetDisplayableUiNotificationDtoList";
import useGetNavItemsInfo from "../hooks/useGetNavItemsInfo";
import { client } from "../services/client";
import { navigateToPreviousLink } from "../static/common";

// This route exists separate from PrivateRoute due to the rule of hooks.
export default function AuthenticationRoute() {
    // Hook for the current location.
    const location = useLocation();

    // State to determine if a page is loading.
    const [pageLoading, setPageLoading] = useState();

    // Hook for navigation.
    const navigate = useNavigate();

    // Navigation point for onSuccess of changing the company number.
    const [fallbackPath, setFallbackPath] = useState();

    // Update the selected logged in user company number.
    // https://react-query.tanstack.com/guides/mutations
    const changeCompany = useMutation(
        ["/lcp-user-management-service/changeCompany"],
        (companyNumberAsString) => {
            return client.post("/lcp-user-management-service/changeCompany", {
                companyNumberAsString,
            });
        },
        {
            onSuccess: (innerData) => {
                // If the fallback page exists and the new company number does not match the old one,
                // navigate to the previous link or the fallback page, while clearing the fallback page state.
                if (fallbackPath && companyNumber !== innerData?.data?.loggedInUserCompanyNumber) {
                    navigateToPreviousLink(location, navigate, fallbackPath);
                }
            },
        }
    );

    // Reset fallback path whenever the location changes.
    useEffect(() => {
        setFallbackPath(undefined);
    }, [location]);

    // Retrieve the basicAccountInfoDtoList.
    const getBasicAccountInfoDtoList = useGetBasicAccountInfoDtoList([
        location?.pathname,
        changeCompany?.data?.data?.loggedInUserCompanyNumber,
    ]);

    // Data for the nav bar.
    const getNavItemsInfo = useGetNavItemsInfo(
        [getBasicAccountInfoDtoList?.data?.data?.companyNumber],
        getBasicAccountInfoDtoList?.isSuccess
    );

    // Data for Account Support Team.
    const getAccountSupportTeamInfoDto = useQuery(
        [
            "/lcp-user-management-service/getAccountSupportTeamInfoDto",
            getBasicAccountInfoDtoList?.data?.data?.rootCustomerNumber,
        ],
        () => {
            return client.post("/lcp-user-management-service/getAccountSupportTeamInfoDto");
        },
        {
            enabled: getBasicAccountInfoDtoList?.isSuccess,
        }
    );

    const getDisplayableUiNotificationDtoList = useGetDisplayableUiNotificationDtoList(
        [getBasicAccountInfoDtoList?.data?.data?.companyNumber],
        getBasicAccountInfoDtoList?.isSuccess
    );

    // Abbreviated access to the company number.
    const companyNumber = getBasicAccountInfoDtoList?.data?.data?.companyNumber;

    // Abbreviated access to the account support team dto.
    const accountSupportTeamDto = getAccountSupportTeamInfoDto?.data?.data?.accountSupportTeamInfoDto;

    // Outlet => Renders the child routes element, if there is one.
    // "context" prop is accessed in child routes as a hook, example below
    // const outletContext = useOutletContext();
    // Query loading indicator global, modals are outside of normal loading sequence.
    return (
        <>
            {(getBasicAccountInfoDtoList?.isLoading ||
                getNavItemsInfo?.isLoading ||
                getDisplayableUiNotificationDtoList?.isLoading ||
                getAccountSupportTeamInfoDto?.isLoading ||
                (pageLoading &&
                    location?.pathname !== "/temporary-issue" &&
                    location?.pathname !== "/temporary-issue-public" &&
                    location?.pathname !== "/technical-issue" &&
                    location?.pathname !== "/account-profile-problem" &&
                    location?.pathname !== "/permission-list" &&
                    location?.pathname !== "/site-work")) && <OverlaySpinner />}
            <Outlet
                context={{
                    accountSupportTeamDto,
                    getBasicAccountInfoDtoList,
                    getAccountSupportTeamInfoDto,
                    getNavItemsInfo,
                    getDisplayableUiNotificationDtoList,
                    changeCompany,
                    companyNumber,
                    setFallbackPath,
                    setPageLoading,
                }}
            />
            <Footer />
        </>
    );
}
