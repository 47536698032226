import { useCallback } from "react";
import NavLink from "react-bootstrap/NavLink";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { refreshSameRoute } from "../static/common";
import { routeDictionary } from "../static/routeDictionary";

export default function NavBarItem({ navName, label, selected }) {
    // Function from i18next for translation.
    const { t: translate } = useTranslation("routes");

    // Pre translate route.
    const translatedRoute = routeDictionary[navName];

    // Callback to cache reference to refresh route.
    const refreshSameRouteCallback = useCallback(() => {
        refreshSameRoute(translatedRoute);
    }, [translatedRoute]);

    return (
        <NavLink
            className={selected ? "lcp-nav-link-selected" : "lcp-nav-link"}
            id={navName}
            key={navName}
            as={Link}
            to={translatedRoute}
            onClick={refreshSameRouteCallback}
        >
            {translate(label ?? navName)}
        </NavLink>
    );
}
