import Cookies from "js-cookie";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Notification({ notificationData, uiNotificationDismissalTimeout }) {
    // Get the cookie.
    const myCookie = Cookies.get("lcp-notification-" + notificationData?.uiNotificationId);

    // State to determine if notification bar is visible.
    const [visible, setVisible] = useState(myCookie === null || myCookie === undefined);

    // Determine dismissal timeout in Minutes.
    const dismissalTimeout = new Date(new Date().getTime() + uiNotificationDismissalTimeout * 60 * 1000);

    // Hide the notification bar.
    const hideNotification = () => {
        if (visible) {
            // Set the cookie name, value, and expiration.
            Cookies.set(
                "lcp-notification-" + notificationData?.uiNotificationId,
                "lcp-notification-" + notificationData?.uiNotificationId,
                { expires: dismissalTimeout }
            );

            // Hide the notification.
            setVisible(false);
        }
    };

    return (
        <>
            {visible && (
                <Container className="lcp lcp-global-notification-container" role="notification" fluid>
                    <Row className="lcp-global-notification-bar-row">
                        <Col xs={{ offset: 1, span: 10 }} className="text-center px-5">
                            <span>{notificationData?.contentData}</span>
                        </Col>
                        <Col className="lcp-global-notification-close-button" xs={1}>
                            {notificationData?.dismissable && (
                                <button
                                    type="button"
                                    className="close lcp-close-icon"
                                    aria-label="Close"
                                    onClick={hideNotification}
                                >
                                    <span aria-hidden="true">X</span>
                                </button>
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}
