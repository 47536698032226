import { useQuery } from "react-query";
import { client } from "../services/client";

const getNavItemsInfoQuery = () => {
    return client.post("/lcp-user-management-service/getPrivateNavigationMenu");
};

// useQuery to fetch data for the nav bar.
//https://react-query.tanstack.com/guides/queries
export default function useGetNavItemsInfo(dependencies = [], enabledBoolean = false) {
    return useQuery(["/lcp-user-management-service/getPrivateNavigationMenu", ...dependencies], getNavItemsInfoQuery, {
        enabled: enabledBoolean,
    });
}
