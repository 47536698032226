import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useCallback, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function PasswordBase({ name, autoComplete, value, onBlur, maxLength, isInvalid, onChange }) {
    // State for showing the password(Defined as false).
    const [showPassword, setShowPassword] = useState(false);

    // handleShowPassword function to unmask or mask the password field.
    const handleShowPassword = useCallback(() => {
        if (showPassword === true) {
            setShowPassword(false);
        } else {
            setShowPassword(true);
        }
    }, [showPassword]);

    // Render the PasswordBase component.
    return (
        <>
            <div>
                <InputGroup>
                    <Form.Control
                        className="lcp-password-input"
                        type={showPassword ? "text" : "password"}
                        name={name}
                        autoComplete={autoComplete}
                        value={value}
                        onBlur={onBlur}
                        maxLength={maxLength}
                        isInvalid={isInvalid}
                        onChange={onChange}
                    />
                    <span className="lcp-password-mask-icon" onClick={handleShowPassword}>
                        {/* Render the eyeball visability based on the showPassword boolean. */}
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </span>
                </InputGroup>
            </div>
        </>
    );
}
