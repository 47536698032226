import { useOutletContext } from "react-router-dom";
import CompanyNumberNavBar from "./CompanyNumberNavBar";
import Notification from "./Notification";
import PrimaryNavBar from "./PrimaryNavBar";
import UserPreferencesNavBar from "./UserPreferencesNavBar";

export default function Header() {
    // Outlet Context.
    const outletContext = useOutletContext();

    // Data for the nav bar.
    const getNavItemsInfo = outletContext?.getNavItemsInfo;

    // List of UI Notifications.
    const getDisplayableUiNotificationDtoList = outletContext?.getDisplayableUiNotificationDtoList;

    // Consolidated for readability in the render.
    const uiNotificationDtoList = getDisplayableUiNotificationDtoList?.data?.data?.uiNotificationDtoList;

    // Consolidate for readability.
    const uiNotificationDismissalTimeout =
        getDisplayableUiNotificationDtoList?.data?.data?.uiNotificationDismissalTimeout;

    return (
        <>
            {uiNotificationDtoList?.map((notification) => {
                return (
                    <Notification
                        key={notification?.uiNotificationId}
                        notificationData={notification}
                        uiNotificationDismissalTimeout={uiNotificationDismissalTimeout}
                    />
                );
            })}
            <CompanyNumberNavBar />
            <PrimaryNavBar navItems={getNavItemsInfo} />
        </>
    );
}
