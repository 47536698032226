import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useOutletContext } from "react-router-dom";
import NavBarItem from "./NavBarItem";
import NavBarItemWithSubMenu from "./NavBarItemWithSubMenu";

export default function PrimaryNavBar({ navItems }) {
    // Outlet Context.
    const outletContext = useOutletContext();

    // useQuery for getAccountSupportTeamInfoDto
    const getAccountSupportTeamInfoDto = outletContext?.getAccountSupportTeamInfoDto;

    // Nav Items
    const navigationMenuItemDtoList = navItems?.data?.data?.navigationMenuItemDtoList;

    // If this errors out we are going to external temp issue.
    if (getAccountSupportTeamInfoDto?.isSuccess) {
        // If power fiber flag is true, render the power fiber version of the primary nav bar.
        if (outletContext?.getAccountSupportTeamInfoDto?.data?.data?.accountSupportTeamInfoDto?.powerFiberFlag) {
            return (
                <Navbar id="powerFiberNavBar" className="lcp-power-fiber-nav-bar" expand="lg" variant="light">
                    <Container className="lcp lcp-nav-container lcp-primary-nav-bar-container" fluid>
                        <Navbar.Brand
                            className="ml-auto lcp-navbar-brand"
                            as={Link}
                            to="https://lightpathfiber.com/"
                            target={"_blank"}
                            name="primaryLogo"
                        >
                            <Container className="lcp lcp-container lcp-nav-logo-container" fluid>
                                <img
                                    src={process.env.REACT_APP_STATIC_IMAGES + "/PowerFiber-Logo.svg"}
                                    alt=""
                                    className="lcp-nav-logo"
                                    width={260}
                                    height={60.97}
                                />
                            </Container>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                {navigationMenuItemDtoList?.map((item, index) => {
                                    const key = index + item?.navName;

                                    // Always skip for power fiber.
                                    if (item?.navName === "product_resources") {
                                        return;
                                    }
                                    if (item?.children?.length > 0) {
                                        return (
                                            <NavBarItemWithSubMenu
                                                key={key}
                                                children={item?.children}
                                                navName={item?.navName}
                                            />
                                        );
                                    } else {
                                        return <NavBarItem key={key} navName={item?.navName} />;
                                    }
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            );
        }
        // Otherwise, render the basic primary nav bar.
        return (
            <Navbar id="primaryNavBar" expand="lg" variant="dark">
                <Container className="lcp lcp-nav-container lcp-primary-nav-bar-container" fluid>
                    <Navbar.Brand
                        className="ml-auto lcp-navbar-brand"
                        as={Link}
                        to="https://lightpathfiber.com/"
                        target={"_blank"}
                        name="primaryLogo"
                    >
                        <Container className="lcp lcp-container lcp-nav-logo-container" fluid>
                            <img
                                src={process.env.REACT_APP_STATIC_IMAGES + "/lp-logo.png"}
                                alt=""
                                className="lcp-nav-logo"
                                width={260}
                                height={60.97}
                            />
                        </Container>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {navigationMenuItemDtoList?.map((item, index) => {
                                const key = index + item?.navName;

                                if (item?.children?.length > 0) {
                                    return (
                                        <NavBarItemWithSubMenu
                                            key={key}
                                            children={item?.children}
                                            navName={item?.navName}
                                        />
                                    );
                                } else {
                                    return <NavBarItem key={key} navName={item?.navName} />;
                                }
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
    // If the call has not succeeded yet, return an empty placeholder.
    return (
        <Navbar id="primaryNavBar" expand="lg" variant="dark">
            <Container className="lcp lcp-nav-container lcp-primary-nav-bar-container" fluid>
                <Navbar.Brand
                    className="ml-auto lcp-navbar-brand"
                    as={Link}
                    to="https://lightpathfiber.com/"
                    target={"_blank"}
                    name="primaryLogo"
                ></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
        </Navbar>
    );
}
