export const customSelectStyles = {
    container: (provided) => ({
        ...provided,
        fontSize: "11px",
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: "#ced4da",
        boxShadow: state?.isFocused ? "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(244 255 0 / 60%)" : "none",
        "&:hover": {
            borderColor: "#ced4da",
        },
        cursor: "pointer",
    }),
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isSelected ? "#e4e4e4" : "#aaa",
        },
        color: "black",
        backgroundColor: state.isSelected ? "#e4e4e4" : "white",
        cursor: "pointer",
    }),
};

export const formSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.selectProps.error ? "#dc3545" : "#ced4da",
        boxShadow: state?.isFocused ? "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(244 255 0 / 60%)" : "none",
        "&:hover": {
            borderColor: state.selectProps.error ? "#dc3545" : "#ced4da",
        },
        cursor: "pointer",
    }),
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isSelected ? "#e4e4e4" : "#aaa",
        },
        color: "black",
        backgroundColor: state.isSelected ? "#e4e4e4" : "white",
        cursor: "pointer",
    }),
};

export const e911ProvisioningSelectStyles = {
    container: (provided) => ({
        ...provided,
        fontSize: "14px",
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: state.selectProps.error ? "#dc3545" : "#ced4da",
        boxShadow: state?.isFocused ? "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(244 255 0 / 60%)" : "none",
        "&:hover": {
            borderColor: "#ced4da",
        },
        cursor: "pointer",
    }),
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isSelected ? "#e4e4e4" : "#aaa",
        },
        color: "black",
        backgroundColor: state.isSelected ? "#e4e4e4" : "white",
        cursor: "pointer",
    }),
};

export const companyNumberSelectStyles = {
    container: (provided) => ({
        ...provided,
        fontSize: "18px",
        color: "white",
        backgroundColor: "#2a2a2a",
        borderColor: "#2a2a2a",
        borderRadius: "5px",
        maxWidth: "325px",
        cursor: "pointer",
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "white",
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "white",
        backgroundColor: "#2a2a2a",
        borderColor: "#2a2a2a",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        cursor: "pointer",
    }),
    valueContainer: (provided) => ({
        ...provided,
        cursor: "pointer",
    }),
    input: (provided, state) => ({
        ...provided,
        color: "white",
        cursor: "pointer",

        input: {
            width: "max-content !important",
            minWidth: "100% !important",
            textAlign: "left",
            gridArea: "1 / 1 / 4 / 4 !important",
            cursor: state.selectProps.menuIsOpen ? "auto" : "pointer",
            caretColor: state.selectProps.menuIsOpen ? "auto" : "transparent",
        },
    }),
    control: (provided, state) => ({
        ...provided,
        color: "white",
        backgroundColor: "#2a2a2a",
        borderColor: "#2a2a2a",
        boxShadow: state?.isFocused ? "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(244 255 0 / 60%)" : "none",
        "&:hover": {
            borderColor: "#2a2a2a",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "white",
        backgroundColor: "#2a2a2a",
        borderColor: "#2a2a2a",
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: "16px",
        color: state?.isFocused || state?.isSelected ? "black" : "white",
        backgroundColor: state?.isFocused || state?.isSelected ? "#e0ff00" : "#2a2a2a",
        borderColor: "#2a2a2a",
        cursor: "pointer",
    }),
};
