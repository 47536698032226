import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routeDictionary } from "../static/routeDictionary";
import { refreshSameRoute } from "../static/common";
import { useCallback } from "react";

export default function DropDownItem({ dropDownNavParentName, navName }) {
    // Function from i18next for translation.
    const { t: translate } = useTranslation("routes");

    // Pre translate route.
    const translatedRoute = routeDictionary[navName];

    // Callback to cache reference to refresh route.
    const refreshSameRouteCallback = useCallback(() => {
        refreshSameRoute(translatedRoute);
    }, [translatedRoute]);

    if (navName === "articles" || navName === "customer_spotlights") {
        return (
            <Dropdown.Item
                key={navName}
                className={dropDownNavParentName + " lcp-dropdown-item"}
                target="_blank"
                href={translatedRoute}
                onClick={refreshSameRouteCallback}
            >
                {translate(navName)}
            </Dropdown.Item>
        );
    } else {
        return (
            <Dropdown.Item
                key={navName}
                className={dropDownNavParentName + " lcp-dropdown-item"}
                as={Link}
                to={translatedRoute}
                onClick={refreshSameRouteCallback}
            >
                {translate(navName)}
            </Dropdown.Item>
        );
    }
}
