import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function NotificationBar({
    errorMessageFlag,
    warningMessageFlag,
    successMessageFlag,
    infoMessageFlag,
    tempMessageFlag,
    message,
}) {
    // Function from i18next for translation.
    const { t: translate } = useTranslation(["common"]);
    if (message === "" || message === null || message === undefined) {
        message = translate("notification.common.message.unexpected.error");
    }
    return (
        <>
            {errorMessageFlag === true && (
                <Container id="divNotificationBarErrorMessage" role="notification">
                    <Row>
                        <Col className="text-center">{message}</Col>
                    </Row>
                </Container>
            )}
            {warningMessageFlag === true && (
                <Container id="divNotificationBarWarningMessage" role="notification">
                    <Row>
                        <Col className="text-center">{message}</Col>
                    </Row>
                </Container>
            )}
            {successMessageFlag === true && (
                <Container id="divNotificationBarSuccessMessage" role="notification">
                    <Row>
                        <Col className="text-center">{message}</Col>
                    </Row>
                </Container>
            )}
            {infoMessageFlag === true && (
                <Container id="divNotificationBarInfoMessage" role="notification">
                    <Row>
                        <Col className="text-center">{message}</Col>
                    </Row>
                </Container>
            )}
            {tempMessageFlag === true && (
                <Container id="divNotificationBarTempMessage" role="notification">
                    <Row>
                        <Col className="text-center">{message}</Col>
                    </Row>
                </Container>
            )}
        </>
    );
}
