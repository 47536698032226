import { Navigate, Outlet, useLocation } from "react-router";

export default function PrivateRoute() {
    // This exists to re-trigger the query when the path changes during navigation, otherwise it will only trigger on refresh.
    const { pathname } = useLocation();

    // Soft check for login.
    if (localStorage.getItem("lcpAuthToken") === null && window.location.pathname !== "/account-profile-problem") {
        sessionStorage.setItem("from", pathname);
        return <Navigate to="/signin" />;
    }

    // Outlet => Renders the child route's element, if there is one.
    return <Outlet />;
}
