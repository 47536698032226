import { useCallback, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";
import DropDownItem from "./DropDownItem";

export default function NavBarItemWithSubMenu({ navName, children }) {
    // Function from i18next for translation.
    const { t: translate } = useTranslation("routes");

    // State to determine if the drop down is shown.
    const [showDropdown, setShowDropdown] = useState(false);

    // Adjust the show state to show dropdown.
    const callShowDropdown = useCallback(() => {
        setShowDropdown((currentState) => !currentState);
    }, []);

    // Adjust the show state to hide the dropdown.
    const callHideDropdown = useCallback(() => {
        setShowDropdown(false);
    }, []);

    return (
        <NavDropdown
            className="lcp-nav-link"
            key={navName}
            title={translate(navName)}
            align="end"
            show={showDropdown}
            onMouseEnter={callShowDropdown}
            onMouseLeave={callHideDropdown}
        >
            {children.map((item) => {
                return <DropDownItem key={item?.navName} dropDownNavParentName={navName} navName={item?.navName} />;
            })}
        </NavDropdown>
    );
}
