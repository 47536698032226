export const XHR_STATUS = {
    /*
     * =======================================================================
     * Success / Failure statuses.
     * ======================================================================
     */

    /**
     * Status code for indicating a lookup could not locate the desired entry.
     */
    XHR_UNKNOWN: -1,

    /**
     * Status code indicating success.
     */
    XHR_SUCCESS: 0,

    /**
     * Status code indicating a general failure has occurred.
     */
    XHR_GENERAL_FAILURE: 1,

    /*
     * =======================================================================
     * Authentication and Authorization statuses (1000 - 1999).
     * ======================================================================
     */

    /**
     * Status code indicating a user is not authenticated.
     */
    XHR_USER_AUTHENTICATION_FAILURE: 1000,

    /**
     * Status code indicating an authentication token was not found.
     */
    XHR_AUTHENTICATION_TOKEN_NOT_FOUND: 1001,

    /**
     * Status code indicating an authentication token has expired.
     */
    XHR_AUTHENTICATION_TOKEN_EXPIRED: 1002,

    /**
     * Status code indicating an authentication refresh has expired.
     */
    XHR_AUTHENTICATION_REFRESH_EXPIRED: 1003,

    /**
     * Status code indicating the logged in user has an account authorization failure.
     */
    XHR_ACCOUNT_AUTH_FAILURE: 1100,

    /**
     * Status code indicating the logged in user has no roles in the database.
     */
    XHR_USER_HAS_NO_ROLES: 1200,

    /**
     * Status code indicating the logged in user has an account authorization failure.
     */
    XHR_USER_INSUFFICIENT_PERMISSION: 1201,

    /**
     * Status code indicating a user is locked out.
     */
    XHR_USER_LOCKED_OUT_FAILURE: 1300,

    /**
     * Status code indicating an internal user is locked out.
     */
    XHR_INTERNAL_USER_LOCKED_OUT_FAILURE: 1301,

    /**
     * Status code indicating a user's configuration data is incorrect.
     */
    XHR_USER_CONFIG_FAILURE: 1400,

    /*
     * =======================================================================
     * User statuses (2000 - 2999).
     * ======================================================================
     */

    /**
     * Status code indicating the logged in user has an account authorization failure.
     */
    XHR_USER_NOT_FOUND: 2000,

    /**
     * Status code indicating a user is inactive.
     */
    XHR_INACTIVE_USER: 2100,

    /*
     * =======================================================================
     * Database statuses (3000 - 3999).
     * ======================================================================
     */

    /**
     * Status code indicating that a database operation failed.
     */
    XHR_DATABASE_FAILURE: 3000,

    /**
     * Status code indicating that a concurrent deletion occurred.
     */
    XHR_CONCURRENT_DELETION: 3100,

    /**
     * Status code indicating that a concurrent update occurred.
     */
    XHR_CONCURRENT_UPDATE: 3101,

    /**
     * Status code indicating that an expected set of data is missing.
     */
    XHR_DATA_MISSING_FAILURE: 3200,

    /**
     * Status code indicating there is a data duplication error.
     */
    XHR_DUPLICATE_DATA: 3201,

    /*
     * =======================================================================
     * Validation statuses (4000 - 4999).
     * ======================================================================
     */

    /**
     * Status code indicating there is a validation error.
     */
    XHR_VALIDATION_FAILURE: 4000,

    /**
     * Status code indicating the email domain disallowed.
     */
    XHR_EMAIL_DOMAIN_DISALLOWED: 4001,

    /**
     * Status code for indicating inadequate data was supplied as input that prevents proper processing.
     */
    XHR_INADEQUATE_INPUT: 4002,

    /*
     * =======================================================================
     * Account statuses (5000 - 5999).
     * ======================================================================
     */

    /*
     * =======================================================================
     * Password statuses (6000 - 6999).
     * ======================================================================
     */

    /**
     * Status code indicating a password policy violation
     */
    XHR_PASSWORD_POLICY_VIOLATION: 6000,

    /**
     * Status code indicating a password reset token was not found.
     */
    XHR_PASSWORD_RESET_TOKEN_NOT_FOUND: 6100,

    /**
     * Status code indicating a password reset token has expired.
     */
    XHR_PASSWORD_RESET_TOKEN_EXPIRED: 6101,

    /**
     * Status code indicating a user's password has expired.
     */
    XHR_PASSWORD_EXPIRED: 6200,

    /*
     * =======================================================================
     * Bill Pay statuses (7000 - 7999).
     * ======================================================================
     */

    /**
     * Status code indicating a billing account is not associated to a specified checking account.
     */
    XHR_BANK_ACCOUNT_ID_ACCOUNT_NUMBER_MISMATCH: 7000,

    /**
     * Status code indicating a payment's account is mismatched to the chosen account number.
     */
    XHR_PAYMENT_ACCOUNT_MISMATCH: 7100,

    /*
     * =======================================================================
     * E911 Provisioning statuses (8000 - 8999).
     * ======================================================================
     */

    /**
     * Status code indicating a E911 Provisioning Authentication is expired.
     */
    XHR_E911_AUTHENTICATION_TOKEN_EXPIRED: 8000,

    /**
     * Status code indicating a E911 Provisioning Authentication is invalid.
     */
    XHR_E911_AUTHENTICATION_TOKEN_INVALID: 8001,

    /*
     * =======================================================================
     * REST Email Service statuses (9000 - 9999).
     * ======================================================================
     */

    /**
     * Status code indicating a connection error to the REST email service.
     */
    XHR_REST_EMAIL_SERVICE_CONNECTION_ERROR: 9000,

    /**
     * Status code indicating a connection error to the REST email server.
     */
    XHR_REST_EMAIL_SERVER_CONNECTION_ERROR: 9001,

    /*
     * =======================================================================
     * Site work statuses (10000 - 10999).
     * ======================================================================
     */

    /**
     * Status code indicating that site work is currently in progress.
     */
    XHR_SITE_WORK_IN_PROGRESS: 10000,

    /*
     * =======================================================================
     * Salesforce error statuses (11000 - 11999).
     * ======================================================================
     */

    /**
     * Status code indicating that there was an error connecting to Salesforce.
     */
    XHR_SALESFORCE_CONNECTION_FAILURE: 11000,

    /**
     * Status code indicating that there was an error authenticating with Salesforce.
     */
    XHR_SALESFORCE_AUTHENTICATION_FAILURE: 11001,

    /**
     * Status code indicating that there was an error with a Salesforce API.
     */
    XHR_SALESFORCE_REST_API_ERROR: 11002,

    /*
     * =======================================================================
     * Miscellaneous statuses (99000 - 99999).
     * ======================================================================
     */

    /**
     * Status code indicating a user specified a user name not associated with the correct brand.
     */
    XHR_USER_BRAND_MISMATCH: 99000,

    /**
     * Status code indicating there is a configuration error that prevented a request from completing successfully.
     */
    XHR_CONFIGURATION_FAILURE: 99001,

    /**
     * Status code for indicating a connection failure occurred.
     */
    XHR_CONNECTION_FAILURE: 99002,
};
